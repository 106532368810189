/* -- keep the defaut theme -- */
@import "minima";
/* -- w3s W3 CSS framework -- */
@import url(https://www.w3schools.com/w3css/4/w3.css);
/* -- font-awesone icons -- */
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
/* -- Google fonts -- */
@import url(https://fonts.googleapis.com/css?family=Crimson+Text);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
/* -- style -- */
html, body {
    font-family: "Open Sans";
  }
h1, h2, h3, h4, h5, h6 {
    font-family: "Crimson Text";
  }